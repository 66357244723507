<template>
  <div class="">
    <v-container class="py-0"> 
      <transition name="fade" mode="out-in" appear>
        <PageTitle class="d-block d-md-none">Landlords</PageTitle>
      </transition>

      <transition name="slide-left-fade" mode="out-in" appear>
        <v-tabs
          v-model="tab"
          class="mt-5 mt-sm-1"
          color="tabColor"
          slider-color="tabColor"
          center-active
          background-color="tertiary"
          show-arrows
          height="55"
        >
          <v-tab ripple>
            All
          </v-tab>
          <v-tab ripple>
            No bank details
          </v-tab>
          <v-tab ripple>
            No email address 
          </v-tab>
          <v-tab ripple>
            In arrears 
          </v-tab>
          <v-spacer></v-spacer>
          <span class="d-none d-md-block">
            <PageTitle>Landlords</PageTitle>
          </span>
        </v-tabs>
      </transition>

      <transition name="slide-fade" mode="out-in" appear>
        <div>
          <v-tabs-items v-model="tab" class="pt-2 tertiary">
            <v-tab-item>
              <Skeleton v-if="!allRecords"/>
              <LandlordsRecordsTable
                :headers="headers"
                v-if="allRecords"
                :tableData="allRecords"
                msg="All"
              />            
            </v-tab-item>
            <v-tab-item>
              <Skeleton v-if="!landlordsBanklessRecords"/>
              <LandlordsRecordsTable
                :headers="headers"
                v-if="landlordsBanklessRecords"
                :tableData="landlordsBanklessRecords"
                msg="Without bank details"
              />            
            </v-tab-item>
            <v-tab-item>
              <Skeleton v-if="!landlordsEmaillessRecords" />
              <LandlordsRecordsTable
                v-if="landlordsEmaillessRecords"
                :headers="headers"
                :tableData="landlordsEmaillessRecords"
                msg="Without email address"
              />           
            </v-tab-item>           
            <v-tab-item>
              <Skeleton v-if="!landlordsOwingRecords.Data" />
              <LandlordsRecordsTable
                v-if="landlordsOwingRecords.Data"
                :headers="headers"
                :tableData="landlordsOwingRecords"
                msg="In arrears"
                showTotPayments="true"
              />            
            </v-tab-item>  
          </v-tabs-items>
        </div>
      </transition>

      <v-row>
        <v-col cols="12">
          <v-expansion-panels multiple class="mt-8">
            <v-expansion-panel>
              <v-expansion-panel-header ripple class="secondary lighten-3 white--text" >
                <div class="d-flex align-center justify-start">
                  <!-- <v-icon class="mr-3" color="white">mdi-account-tie</v-icon> -->
                  <v-icon class="mr-3" color="white">mdi-pencil</v-icon>
                  Edit Landlord
                </div>
                <template v-slot:actions>
                  <v-icon color="white">$expand</v-icon>
                </template>
                </v-expansion-panel-header>
              <v-expansion-panel-content>
                <EditLandlords />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
        
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LandlordsRecordsTable from '../components/Landlords/LandlordsRecordsTable.vue'
import Skeleton from '../components/SkeletonLoaders/SkeletonLoaderTable.vue'
import EditLandlords from '@/components/Edits/EditLandlord.vue'
import PageTitle from '@/components/Slots/PageTitle.vue';

export default {
  components: {
    LandlordsRecordsTable,
    Skeleton,
    EditLandlords,
    PageTitle
  },
  data() {
    return {
      tab: null,
      headers: [
      {
        text: 'Name',
        align: 'left',
        value: 'LandlordName',
      },
      { text: 'Address', value: 'LandlordAddress' },
      { text: 'Balance', value: 'LandlordAccountBalance', sortable: true, sort: (a, b) => b - a  },
      { text: 'Account no.', value: 'LandlordAccountNumber' },
      // { text: 'E-mail', value: 'LandlordEmail' },
      // { text: 'Support', value: 'Message' },
      { text: '', value: 'actions', align: 'end' },
    ],
    }
  },
  created() {
    this.$store.dispatch('fetchLandlordRecords')
  },
  computed: {
    ...mapState({
        allRecords: state => state.LandlordsRecords.allLandlordRecords,
        landlordsBanklessRecords: state => state.LandlordsRecords.landlordsBanklessRecords,
        landlordsEmaillessRecords: state => state.LandlordsRecords.landlordsEmaillessRecords,
        landlordsOwingRecords: state => state.LandlordsRecords.landlordsOwingRecords,
        landlordsTenPercentBalanceDifference: state => state.LandlordsRecords.landlordsTenPercentBalanceDifference
    }),
  }
}
</script>

